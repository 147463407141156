import {TrackingAction, TrackingActionType, TrackingFeature, TrackingFeatureLabels, TrackingFeatureStatus} from "../types";
import Tile from "./tile";
import {getPagecluster} from "@otto-ec/global-resources/misc";
import {eventQBus} from "@otto-ec/global-resources/event-q-bus";

export class TrackingServiceLegacy {

    public sendSmallShoppromoInitialLoadedTrackingEvent(featureOrder: number, featureIndex: number, featureId: string, personalizationContext: string, tilesCount: number, tilesToTrack: Array<Tile>, masFeatureTrackingLabels: TrackingFeatureLabels, moduleType : string): void {
        const trackingFeatures = [
            this.createSmallShoppromoFeature(featureOrder, featureIndex, featureId, tilesCount, personalizationContext, "loaded", masFeatureTrackingLabels, moduleType),
            ...tilesToTrack.map((tile) => {
                tile.setIsTracked()
                return this.createSmallShoppromoTileFeature(tile, featureId, "loaded", moduleType)
            })
        ];

        eventQBus.emit("tracking.bct.addFeaturesToPageImpression", trackingFeatures);
    }


    public sendSmallShoppromoSlideTrackingEvent(featureOrder: number, featureIndex: number, featureId: string, personalizationContext: string, tilesCount: number, tilesToTrack: Array<Tile>, movementType: TrackingActionType, masFeatureTrackingLabels: TrackingFeatureLabels, moduleType: string) {
        const trackingFeatures: TrackingFeature[] = [
            this.createSmallShoppromoFeature(featureOrder, featureIndex, featureId, tilesCount, personalizationContext, "visible", masFeatureTrackingLabels, moduleType),
            ...tilesToTrack
                .filter((tile) => !tile.isTrackedAsVisible())
                .map((tile) => {
                    tile.setIsTrackedAsVisible()
                    return this.createSmallShoppromoTileFeature(tile, featureId, "visible", moduleType)
                })
        ];
        const trackingAction: TrackingAction = {name: movementType, features: trackingFeatures}

        eventQBus.emit('tracking.bct.submitAction', {}, trackingAction);
    }


    public sendSmallShoppromoClickTrackingEvent(featureOrder: number, featureIndex: number, featureId: string, personalizationContext: string, filledSlots: number, tile: Tile, masFeatureTrackingLabels: TrackingFeatureLabels, moduleType: string) {
        const trackingFeatures = [
            this.createSmallShoppromoFeature(featureOrder, featureIndex, featureId, filledSlots, personalizationContext, "clicked", masFeatureTrackingLabels, moduleType),
            this.createSmallShoppromoTileFeature(tile, featureId, "clicked", moduleType)
        ];
        const trackingAction: TrackingAction = {
            name: "click",
            features: trackingFeatures
        }
        const wkTrackingData = {
            "wk.promo_AttributionFeature": `ShopPromotionSmall_${getPagecluster()}`,
            "wk.promo_AttributionSource": tile.teslaModel
        }

        eventQBus.emit('tracking.bct.submitMoveAction', wkTrackingData, trackingAction);
        eventQBus.emit("tracking.bct.trackOnNextPageImpression", {"promo_Click": "ShopPromotionSmall"});
    }

    private createSmallShoppromoFeature(featureOrder: number, featureIndex: number, featureId: string, filledSlots: number, personalizationContext: string, status: TrackingFeatureStatus, masFeatureTrackingLabels: TrackingFeatureLabels, moduleType: string): TrackingFeature {
        const trackingLabels: TrackingFeatureLabels = {
            promo_FeatureNumber: [(featureIndex + 1).toString()],
            promo_FilledSlots: [(filledSlots).toString()],
            promo_Feature: ["true"],
            ...masFeatureTrackingLabels
        };
        if (personalizationContext) {
            trackingLabels.promo_Context = [personalizationContext]
        }

        return {
            id: (moduleType == "PERSONALIZED_SHOPPROMO_SMALL" ? 'ft3_spsnfpe' : 'ft3_spsnfma') + `_${featureId}`,
            name: "ShopPromotionSmall",
            position: featureOrder,
            status: status,
            labels: trackingLabels
        }
    }

    private createSmallShoppromoTileFeature(tile: Tile, featureId: string, status: TrackingFeatureStatus, moduleType: string): TrackingFeature {
        const parentId = (moduleType == "PERSONALIZED_SHOPPROMO_SMALL" ? 'ft3_spsnfpe' : 'ft3_spsnfma') + `_${featureId}`
        const tileTrackingFeature: TrackingFeature = {
            id: parentId + "_tile" + tile.position,
            parentId: parentId,
            name: "ShopPromotionSmall-Tile",
            position: tile.position,
            status: status,
            labels: tile.featureTrackingLabels
        };
        tileTrackingFeature.variationId = tile.variationId ? tile.variationId : "none"
        return tileTrackingFeature
    }

    sendMiniAction(featureId: string, tilesToTrack: Tile[], action: string, moduleType: string) {
        const parentId = (moduleType == "PERSONALIZED_SHOPPROMO_SMALL" ? 'ft3_spsnfpe' : 'ft3_spsnfma') + `_${featureId}`
        const miniActions = tilesToTrack
            .filter((tile) => !tile.isTrackedAsVisible())
            .map((tile) => {
                tile.setIsTrackedAsVisible()
                return {featureId: parentId + "_tile" + tile.position, action: action}
            })
        if (miniActions.length > 0) {
            miniActions.push({featureId: parentId, action: action})
            eventQBus.emit('tracking.bct.submitMiniActions', miniActions);
        }
    }
}