import {PromoType, TrackingAction, TrackingActionType, TrackingFeature, TrackingFeatureLabels, TrackingFeatureStatus, WkTrackingData} from "../types";
import Slide from "./slide"

/*                                                                                           */
/*                                                                                                       */
export class TrackingServiceLegacy {

    private global: OttoGlobal;

    constructor() {
        /*                                    */
        this.global = window.o_global
        this.global = window.o_global || {};
        if (!this.global.eventQBus) {
            /*                                                         */
            /*         */
            this.global.eventQBus = {
                emit: () => {
                    if (window.o_global.debug.status().activated) {
                        /*                                  */
                        console.log("Tracking not available.");
                    }
                }
            }
        }
    }

    sendLargeShopPromoInitialLoadedTrackingEvent(promoType: PromoType, featureOrder: number, featureId: string, filledSlots: number, slides: Array<Slide>,
                                                 featureLabels: TrackingFeatureLabels, masFeatureTrackingLabels: TrackingFeatureLabels): void {
        if (slides.length === 0) {
            return
        }
        const trackingFeatures = this.createTrackingFeatures(promoType, featureOrder, featureId, filledSlots, featureLabels, masFeatureTrackingLabels, slides, "loaded", "loaded");
        this.global.eventQBus.emit("tracking.bct.addFeaturesToPageImpression", trackingFeatures);
    }

    sendLargeShoppromoSlideTrackingEvent(promoType: PromoType, featureOrder: number, featureId: string, filledSlots: number, slides: Array<Slide>,
                                         featureLabels: TrackingFeatureLabels, masFeatureTrackingLabels: TrackingFeatureLabels, movementType: TrackingActionType) {
        const slidesForTracking = slides.filter(slide => !slide.isTrackedAsVisible());
        slidesForTracking.forEach(slide => slide.setIsTrackedAsVisible());
        const trackingAction: TrackingAction = {
            name: movementType,
            features: this.createTrackingFeatures(promoType, featureOrder, featureId, filledSlots, featureLabels, masFeatureTrackingLabels, slidesForTracking, "visible", "visible")
        }
        this.global.eventQBus.emit('tracking.bct.submitAction', {}, trackingAction);

    }

    sendLargeShopPromoClickTrackingEvent(promoType: PromoType, featureOrder: number, featureId: string, filledSlots: number, slide: Slide,
                                         featureLabels: TrackingFeatureLabels, masFeatureTrackingLabels: TrackingFeatureLabels) {
        const trackingAction: TrackingAction = {
            name: "click",
            features: this.createTrackingFeatures(promoType, featureOrder, featureId, filledSlots, featureLabels, masFeatureTrackingLabels, [slide], "clicked", "unused")
        }
        const pageCluster = window.o_util.misc.getPagecluster();
        const wkTrackingData: WkTrackingData = this.createWkTrackingData(promoType, pageCluster, slide);

        this.global.eventQBus.emit('tracking.bct.submitMoveAction', wkTrackingData, trackingAction);
        this.global.eventQBus.emit("tracking.bct.trackOnNextPageImpression", {"promo_Click": promoType});
    }

    sendLargeShopPromoLeverTrackingEvent(promoType: PromoType, featureOrder: number, featureId: string, filledSlots: number, slide: Slide,
                                         featureLabels: TrackingFeatureLabels, masFeatureTrackingLabels: TrackingFeatureLabels) {
        const trackingAction: TrackingAction = {
            name: "open",
            features: this.createTrackingFeatures(promoType, featureOrder, featureId, filledSlots, featureLabels, masFeatureTrackingLabels, [slide], "clicked", "clicked")
        }
        this.global.eventQBus.emit('tracking.bct.submitAction', {}, trackingAction);
    }

    sendMiniAction(promoType: PromoType, featureId: string, slidesToTrack: Slide[], action: string) {
        const miniActions = slidesToTrack
            .filter((slide) => !slide.isTrackedAsVisible())
            .map((slide) => {
                slide.setIsTrackedAsVisible()
                return {featureId: this.getIdPrefixForPromoType(promoType) + "_" + featureId + "_tile" + slide.position, action: action}
            })
        if (miniActions.length > 0) {
            miniActions.push({featureId: this.getIdPrefixForPromoType(promoType) + "_" + featureId, action: action})
            this.global.eventQBus.emit('tracking.bct.submitMiniActions', miniActions);
        }
    }

    private createTrackingFeatures(promoType: PromoType, featureOrder: number, featureId: string, filledSlots: number, featureLabels: TrackingFeatureLabels, masFeatureTrackingLabels: TrackingFeatureLabels, slides: Array<Slide>, status: TrackingFeatureStatus, leverStatus: TrackingFeatureStatus): TrackingFeature[] {
        const trackingFeatures = [this.createLargeShopPromoTrackingFeature(promoType, featureOrder, featureId, filledSlots, status, featureLabels, masFeatureTrackingLabels)];

        slides.forEach((slide) => {
            const slideTrackingPayload = this.createLargeShoppromoTileTrackingFeature(promoType, slide.position, featureId, status, slide.featureLabels);
            trackingFeatures.push(slideTrackingPayload);
            const leverLabel = this.createLeverTagLabel(slideTrackingPayload["id"], leverStatus, slide);
            if (leverLabel) {
                trackingFeatures.push(leverLabel);
            }
        });
        return trackingFeatures;
    }

    private createWkTrackingData(promoType: "ShopPromotionLarge" | "ShopPromotionLargeDynamo", pageCluster: string | null, slide: Slide): WkTrackingData {
        if (promoType === 'ShopPromotionLarge') {
            return {
                "wk.promo_AttributionFeature": promoType + "_" + pageCluster,
                "wk.promo_AttributionSource": "manual",
                "wk.promo_AttributionScore": "ShopPromotionLarge"
            }
        } else {
            /*                         */
            return {
                "wk.promo_AttributionFeature": promoType + "_" + pageCluster,
                "wk.promo_AttributionSource": slide.featureLabels["dynamo_Affinity"][0],
                "wk.promo_AttributionScore": slide.featureLabels["dynamo_Score"][0]
            }
        }
    }

    private createLargeShopPromoTrackingFeature(promoType: string, featureOrder: number, featureId: string, filledSlots: number, status: TrackingFeatureStatus,
                                                featureLabels: TrackingFeatureLabels, masFeatureTrackingLabels: TrackingFeatureLabels): TrackingFeature {
        const mergedTrackingLabels = {
            ...featureLabels,
            ...masFeatureTrackingLabels
        }
        return {
            id: this.getIdPrefixForPromoType(promoType) + "_" + featureId,
            name: promoType,
            position: featureOrder,
            status: status,
            labels: mergedTrackingLabels
        }
    }

    private getIdPrefixForPromoType(promoType: string): string {
        if (promoType === "ShopPromotionLarge") {
            /*                     */
            return "ft3_splm";
        } else {
            /*                         */
            return "ft3_spla";
        }
    }

    private createLargeShoppromoTileTrackingFeature(promoType: string, positionInSlider: number, featureId: string, status: TrackingFeatureStatus, labels: TrackingFeatureLabels): TrackingFeature {
        const idPrefix = this.getIdPrefixForPromoType(promoType);
        return {
            id: idPrefix + "_" + featureId + "_tile" + positionInSlider,
            parentId: idPrefix + "_" + featureId,
            name: promoType + "-Tile",
            position: positionInSlider,
            status: status,
            labels: labels
        }
    }

    private createLeverTagLabel(parentId: string, status: TrackingFeatureStatus, slide: Slide): TrackingFeature | null {
        if (!slide.hasLever || !slide.leverTrackingPayload) {
            return null;
        }
        return {
            ...slide.leverTrackingPayload,
            id: parentId + "_b",
            parentId: parentId,
            status: status,
            position: slide.position
        };
    }
}